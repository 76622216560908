div.landing_page_logos {
	border-bottom: solid 1px $grey;
	margin-bottom: $default-margin;

	div.logo {
		padding: $default-padding/2;
		text-align: center;
		border-right: solid 1px $grey;
		color: $dark-grey;

		@media only screen and ($md) {
			border: solid 1px $grey;
		}

		@media only screen and ($mobile) {
			flex-basis: 100%;
			max-width: 100%;
			border:none;
			border-bottom: solid 1px $grey;
			&:first-child {
				border-top: solid 1px $grey;
			}
		}


		&:last-child {
			border-right: none;
			@media only screen and ($md) {
				border-right: solid 1px $grey;
			}
			@media only screen and ($xs) {
				border-right: none;
			}
		}

		h2 {
			font-weight: 500;
			font-size: $font-size;

			a {
				text-decoration: none;
				color: inherit;
			}

			span {
				color: $very-dark-grey;
				font-size: $font-size-l;
				font-weight: bold;
				display: block;
			}
		}
	}
}

section.landing_page{
	margin-top:$default-margin*2;
}
