div.banner-img.metier_sante {
	background-image: none;
	background-color: $grey;

	.banner-text {
		.text {
			span {
				background-color: $orange;
			}
		}
	}
}

div.metier_view {
	div.img {
		background-image: url("../img/agenda.jpg");
		background-repeat: no-repeat;
		background-size: cover;
		background-position: top right;
		@media only screen and ($desktop) {
			display:none;
			background-image: none !important;
		}
	}

	div.text {
		text-align: justify;
		padding: $default_padding;
	}
}
.img-metier{
	text-align:center;
	margin:$default-margin auto $large-margin;
}
