h1,
h2,
h3,
h4,
h5 {
	margin: 0;
	padding: 0;
	color: $very-dark-grey;
}

body {
	margin: 0;
	padding: 0;
	font-family: $font-family;
	font-size: $font-size;
	background-color: white;
	@media only screen and (max-width:$stellarnav-breakpoint) {
		margin-top: 80px;
	}
}

input, textarea, select, button{
	font-family: $font-family;
	font-size: $font-size;
}

a {
	color: $very-dark-grey;
	text-decoration: none;

	&:hover {
		color: black;
	}

	&.external {
		color: inherit;
	}

	&.internal {
		color: inherit;
	}
}

.row {
	margin: 0;

	& > div {
		padding: 0;
	}
}

main {
	margin-bottom: $default-margin*4;

	&.contact,
	&.login,
	&.metier_pro,
	&.metier_sante {
		margin-bottom: 0;
	}
}

img {
	max-width: 100%;
}

div.text-block {
	margin: 0;

	.img {
		vertical-align: middle;
		text-align: center;
	}

	.box {
		padding: $default-padding;

		&.title-block {
			padding-bottom: 0;
		}

		p {
			margin-top: 0;
			margin-bottom: 8px;
			text-align: justify;
		}
	}
}

div.button {
	margin-bottom: $default-margin;

	&:last-child {
		margin-bottom: 0;
	}

	&.contact {
		padding: $default_margin 0;
	}

	.button_link {
		color: white !important;
		text-transform: uppercase;
		background: $orange;
		padding: $default-padding/2 $default-padding;
		border-radius: $default-radius;
		display: inline-block;
		border: none;
		cursor: pointer;

		&:hover {
			background: #f7a256;
			-webkit-box-shadow: 0 5px 40px -10px rgba(0,0,0,0.57);
			-moz-box-shadow: 0 5px 40px -10px rgba(0,0,0,0.57);
			box-shadow: 5px 40px -10px rgba(0,0,0,0.57);
			transition: all 0.1s ease 0s;
		}
	}
}

ul {
	li {
		margin-bottom: $default_margin/2;
	}
}

table {
	width: 100%;
	border-collapse: collapse;

	td,
	th {
		border: solid 1px $main-color;
		padding: $default-padding/4;

		&.center {
			text-align: center;
		}
	}
}

span.note,
tfoot {
	font-size: $font-size-xs;
}

textarea {
	font-family: $font-family;
}

form {
	input[type="text"],
	input[type="email"],
	input[type="password"],
	textarea {
		margin: $default-margin 0 0;
		width: 100%;
		height: 40px;
		font-size: $font-size-s;
		color: $very-dark-grey;
		border: solid 1px $dark-grey;
		padding: $default-padding/2;
		box-sizing: border-box;
		border-radius: $default-radius;
	}

	input[type="text"]:first-child {
		margin-top: 0;
	}

	textarea {
		height: 250px;
	}

	&.with_label {
		label {
			display: block;
			font-size: $font-size-s;
		}

		input {
			margin: $default-margin/2 0 $default-margin*2;

			&:last-child {
				margin-bottom: $default-margin;
			}
		}
	}
}

address {
	font-style: normal;
}

.flex-vertical-align-container {
	display: flex;
	flex-direction: column;

	.flex-vertical-align {
		&.grow-0 {
			flex-grow: 0;
		}

		&.grow-25 {
			flex-grow: 0.25;
		}

		&.grow-50 {
			flex-grow: 0.5;
		}

		&.grow-75 {
			flex-grow: 0.75;
		}

		&.grow-1 {
			flex-grow: 1;
		}
	}
}

div.row.legals {
	h1,
	h3 {
		margin-top: $default-margin*2;
	}
}

div.stats {
	background-color: $light-grey;
	color: $dark-grey;
	font-size: $font-size-l;

	& > div {
		border-right: solid 1px $grey;

		&:last-child {
			border-right: none;
			@media only screen and ($lg) {
				border-right: solid 1px $grey;
			}
		}
		@media only screen and ($lg) {
			border: solid 1px $grey;
		}
		@media only screen and ($tablet) {
			border: none;
			border-top: solid 1px $grey;
		}

		div.box {
			padding: $default-padding*2;
			text-align: center;

			span {
				color: $very-dark-grey;
				font-size: $font-size-ll;
				font-weight: bold;
				display: block;
			}
			@media only screen and (max-width:1375px) {
				font-size: $font-size-s;
			}
			@media only screen and ($lg) {
				font-size: $font-size;
			}
		}
	}
}

aside,
blockquote {
	text-align: justify;
	border: solid 1px $main-color;
	padding: 40px;
	margin: 60px $default-padding 40px;
	font-size: $font-size-xs;

	&:before {
		position: relative;
		top: -75px;
		display: flex;
		margin-bottom: -70px;
	}

	h4,
	span.title {
		color: $orange;
		font-weight: 700;
	}
}

aside:before {
	content: url("../img/options.png");
}

blockquote {
	&:before {
		content: url("../img/options.png");
	}

	cite {
		width: 100%;
		text-align: right;
		display: block;
		margin-top: $default-margin;
	}
}

main.metier_pro,
main.metier_sante {
	aside {
		font-size: $font-size;
	}
}

span.mise_en_valeur {
	color: $orange;
	font-weight: bold;

	strong {
		font-weight: bold;
	}

	a {
		color: $orange;
	}
}

strong {
	font-weight: normal;

}

body.dev {
	strong {
		color: blue !important;
	}
	* {
		outline: 1px solid #ddd;
	}
}

#totop {
	bottom: 10px;
	right: 10px;
	cursor: default;
	height: 60px;
	width: 60px;
	position: fixed;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	z-index: 9999;

	img {
		opacity: 0;
		-webkit-transition: opacity 1s ease;
		transition: opacity 1s ease;
	}

	&.scroll {
		display: block;
		cursor: default;

		img {
			cursor: pointer;
			opacity: 1;
			-webkit-transition: opacity 2s ease;
			transition: opacity 2s ease;
		}
	}
}

.error {
	color: red;
	text-align: left;
	font-size: $font-size-s;
}

.orange {
	color: $orange;
	font-size: $font-size-s;
}

.g-recaptcha {
	display: none;
}

.mail_sent {
	color: $green;
	font-weight: 700;
	padding: $default-padding;
	margin: $default-margin*10 $default-margin*4;
	border: solid 1px $dark-grey;

	div.button .button_link{
		text-transform:inherit;
		font-weight: normal;
	}
}

div.button.prdv_link {
	.button_link {
		background-color: $dark-grey;
	}
}

.conditions {
	h3 {
		margin: $default-margin*2 0 0;
	}
}

.center {
	text-align: center !important;
}

.grecaptcha-badge{
	display:none;
}

.asterisk{
	font-family: "Open Sans","HelveticaNeue","Helvetica Neue",Helvetica,Arial,sans-serif;
	font-size: $font-size-l !important;
}


ul#temoignages li{
	margin-bottom:$default-margin;
}
