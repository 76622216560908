footer {
	background-color: $main-color;
	color: white;
	font-size: $font-size-xs;

	div.row {
		div.box {
			padding: $default-padding*2;

			@media only screen and ($lg) {
			    padding: $default-padding*1.5;
			}

			@media only screen and ($sm) {
			    padding: $default-padding;
			}

			h4 {
				border-bottom: solid 2px white;
				font-size: $font-size-s;
				color:$light-grey;

				a {
					color: inherit;
					font-weight: inherit;
				}
			}

			a {
				color: white;
				font-weight: 100;

				&:hover {
					color: $grey;
				}
			}

			ul {
				margin-left: -40px;
				list-style: none;

				li {
					margin-bottom: $default-margin/3;
				}
			}
		}

		.discret{
			color:$grey;
		}

		&.double_col {
			.box {
				padding: 0;
			}
		}
	}
}

div.copyright {
	font-size: $font-size-xs;
	color: $dark-grey;

	div.box {
		padding: $default-padding*2;
	}

	.annuaire {
		text-align: right;

		a {
			color: $dark-grey;
		}
	}
}
