header {
	&.border_bottom {
		border-bottom: solid 1px $grey;
	}

	div.logo {
		background-image: url("../img/logo-e-agenda-500-130.png");
		background-size: contain;
		background-repeat: no-repeat;
		background-position: $margin-logo 0;
		height: ($header-height)-($margin-logo*2);
		margin: $margin-logo 0 $margin_logo $margin_logo;
		@media only screen and ($lg) {
			margin: $margin-logo/2;
			background-position: 0;
		}
		@media only screen and (max-width:880px) {
			background-position: 0;
		}
		@media only screen and (max-width:$stellarnav-breakpoint) {
			height: 40px;
			margin-top: 20px;
			margin-bottom: 20px;
		}
	}

	div.row > div {
		@media only screen and (max-width:$stellarnav-breakpoint) {
			flex-basis: 50%;
			max-width: 50%;
		}
	}
	@media only screen and (max-width:$stellarnav-breakpoint) {
		position: fixed;
		top: 0;
		width: 100%;
		background-color: white;
		border-bottom: solid 1px $grey;
	}
}
