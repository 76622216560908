div.login {

	div.img {
		background-image: url("../img/login-agenda-en-ligne.jpg");
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center right;
		@media only screen and ($desktop) {
			display:none;
			background-image: none !important;
		}
	}

	h3 {
		text-align: center;
	}

	div.col_form{
		margin:$default-margin*4 auto;
	}

	div.form {
		background-color: white;
		height: 100%;
		padding: $default-padding*2;
		box-sizing: border-box;

		form {
			margin-top: $default_margin;
		}

		div.button {
			margin: $default-margin*2 0;

			a {
				cursor: pointer;
				width: 100%;
				padding: $default-padding/2 0;
				text-align:center;
			}

			&.mobile_link {
				display: none;
				@media only screen and (max-width:$stellarnav-breakpoint) {
					display: block;
				}
			}
		}

		#submit_button {
			display: none;
		}
	}

	.error {
		text-align: center;
		border: solid 1px red;
		padding: $default-padding/2;
		background-color: #ffdbdb;
		color: black;
	}
}

.tooltip_templates {
	display: none;
}

.tooltipster-sidetip .tooltipster-content {
	padding: $default-padding*1.5;
	font-size: $font-size-s;
	box-sizing: border-box;
	color: black !important;

	h3,
	h4 {
		font-weight: 700;
		color: black !important;
	}

	h3 {
		margin-bottom: $default-margin/2;
	}

	h4 {
		font-weight: normal;
	}

	div.button .button_link {
		width: 100%;
		text-align: center;
		box-sizing: border-box;
	}

	div.prdv_link {
		font-size: $font-size-xs;

		div.button {
			margin-top: $default-margin/2;

			.button_link {
				background-color: $dark-grey;
				font-weight: normal;
				font-size: $font-size-s;
			}
		}
	}

	form {
		margin-top: $default-margin;

		input[type=email],
		input[type=password],
		input[type=text] {
			margin: 0 0 $default-margin;
			font-size: $font-size-xs;
		}

		div.button.mobile_link,
		input#submit_button {
			display: none;
		}
	}
}


#connect_bureau{
	display:none;
	@media only screen and (max-width:$stellarnav-breakpoint) {
		display:inline-block;
	}
}

#block_mdp_oublie{
	display:none;
}
#mdp_oublie_error{
	color:red;
	margin-top:$small-margin;	
}
