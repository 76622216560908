.tooltipster-base {
	height:auto !important;


	#close-tooltipser {
		display: none;
	}
	@media only screen and (max-width:$stellarnav-breakpoint) {
		min-width: inherit !important;
		width: 100% !important;
		height: 100% !important;
		bottom: 0 !important;
		top: 0 !important;

		.tooltipster-box {
			margin-top: 0 !important;
		}

		.tooltipster-content {
			padding: 80px 30px 30px 30px !important;
		}

		#close-tooltipser {
			display: block;
		}
		.mobile_link{
			display:block !important;
		}
	}

}
