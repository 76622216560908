div.price {
	background-color: $main-color;
	padding: $default-padding*2;

	@media only screen and ($desktop) {
		height:auto
	}

	div.infos {
		color: white;
		height: 100%;

		@media only screen and ($desktop) {
			text-align: center;
			div.button{
				margin:$default-margin;
			}
		}

		span {
			font-size: $font-size-l;
			//display: block;


			&.price {
				font-size: $font-size-xxl;
			}
		}
		a.button_link{
			margin-top:$default-margin;
			text-transform:inherit;
			font-size: $font-size;
		}
	}


	div.check_list {
		background-color: white;
		padding: $default-padding $default-padding*2;

		ul {
			margin: 0;
			padding: 0;

			li {
				font-size: $font-size-l;
				text-align: left;
				margin-bottom: $default-margin*2;
				list-style: none;

				&:last-child {
					margin-bottom: 0;
				}

				img {
					margin-right: $default-margin;
					vertical-align: middle;
				}
			}
		}
	}
}
