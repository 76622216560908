div.row.contact {

	div.img {
		background-image: url("../img/contact-agenda-en-ligne.jpg");
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center right;
		@media only screen and ($desktop) {
			display:none;
			background-image: none !important;
		}
	}

	div.form {
		background-color: white;
		height: 100%;
		padding: $default-padding;
		box-sizing: border-box;
		@media only screen and ($sm) {
			padding: $default-padding;
		}
		p, h1{
			text-align:left;
		}
		img.tel{
			vertical-align:middle;
		}
		form{
			div.box{
				padding:$padding-s 0;
				box-sizing: border-box;

				&#form_name{
					padding-right:$padding-s;
				}
				&#form_email{
					padding-left:$padding-s;
				}
				@media only screen and ($sm) {
					&#form_name, &#form_email{
						padding-right:0;
						padding-left:0;
					}
				}

				input[type="email"], textarea{
					margin-top:0px;
				}
				textarea{
					height:150px;
				}
				p{
					margin-top:0px;
				}

			}


		}
	}
}
