div.banner-img {
	background-size: cover;
	height: $banner-height;
	background-image: url("../img/logiciel-agenda.jpg");
	background-position: 50% 50%;
	@media only screen and ($mobile) {
		height: auto;
		background-image: none !important;
		padding:$default-padding;
	}

	&#banner_lp_agenda,
	&#banner_lp_sante_agenda {
		background-image: url("../img/agenda-en-ligne.jpg");
	}

	&#banner_lp_rdv,
	&#banner_lp_sante_rdv {
		background-image: url("../img/prendre-rdv-en-ligne.jpg");
	}

	&#banner_lp_rappel,
	&#banner_lp_sante_rappel {
		background-image: url("../img/rappel-rdv-sms.jpg");
	}

	&#banner_lp_sante_telesec,
	&#banner_lp_secretariat {
		background-image: url("../img/solutions-telesecretariat.jpg");
	}

	&#banner_offre_sante {
		background-image: url("../img/logiciel-agenda-medecin.jpg");
	}

	&#banner_offre_pro {
		background-image: url("../img/agenda-en-ligne-pro.jpg");
	}

	&#banner_offre_beaute {
		background-image: url("../img/agenda-estheticienne-coiffure.jpg");
	}

	&#banner_offre_mairie {
		background-image: url("../img/agenda-mairies.jpg");
	}

	&#banner_offre_telesecretariat {
		background-image: url("../img/offre-telesecretariat.jpg");
	}
	&#banner_offre_particulier {
		background-image: url("../img/prendre-rendez-vous-par-internet.jpg");
	}



	&.tech_agenda,
	&.tech_prdv {
		background-image: url("../img/fonctionnalites_agenda_en_ligne.jpg");
	}

	.banner-text {
		height: $banner-height;
		@media only screen and ($mobile) {
			height: auto;
		}

		.text {
			text-align: right;
			color: white;
			font-weight: 500;

			span {
				background-color: $main-color;
				padding: $default-padding/2 $default-padding*4 $default-padding/2 $default-padding;
				font-size: $font-size-l;
				color: white;
			}
		}
		@media only screen and ($tablet) {
			.text {
				text-align:center;
				span {
					padding: $default-padding/2;
					@media only screen and ($tablet) {
						display:block;
					}
				}
			}
		}

	}
}
