.stellarnav {
	background-color: white;

	#retour_accueil {
		display: none;
	}

	ul {
		text-align: right;
		margin-right: $default-margin/2;

		ul {
			border: solid 1px $light-grey;
		}
	}

	li {
		font-size: $font-size;

		&#menu_signin {
			padding: $default_padding/2 0;

			a {
				color: white;
				background-color: $orange;
				padding: $default_padding/2;
			}
		}

		&#menu_login {
			cursor: pointer;
		}

		a {
			color: $very-dark-grey;
			text-align: left;
			padding: $default-padding;

			&:hover {
				color: black;
			}

			&.current {
				border-bottom: solid 2px $orange;
				font-weight: 600;
			}

			span.top {
				display: block;
				color: $very-dark-grey;
				font-weight: 700;
			}
		}

		ul {
			margin-top: 0;
			background-color: white;
		}
	}

	&.fixed {
		border-bottom: solid 1px $grey;

		&.mobile {
			border-bottom: none;
		}

		span.top {
			display: none;
		}

		a#retour_accueil {
			display: block;
			position: relative;
			float: left;
			width: 200px;

			div.logo {
				padding: 0;
				margin: 0 0 0 40px;
				background-position: 50% 50%;
			}
		}
	}
	@media only screen and (max-width:$stellarnav-breakpoint) {
		a#retour_accueil {
			display: none;
		}
	}

	li li {
		a {
			padding: 0 0 $default-padding/2;
			margin: $default-padding $default-padding/2 $default-padding/2;
			border-bottom: solid 1px $light-grey;
			font-size: $font-size-s;
			text-align: left;
		}

		&:last-child {
			a {
				border-bottom: 0;
			}
		}
	}
}

.stellarnav > ul > li > a {
	padding: $default_padding $default_padding*1.5 $default_padding 0;
	@media only screen and ($desktop) {
		padding: $default_padding/2 $default_padding $default_padding/2 0;
	}

	&.current {
		padding-top: ($default_padding)+2;
	}
}

.stellarnav > ul > li.has-sub > a {
	cursor: default;
}

.stellarnav > ul > li.has-sub > ul > li > a.current_sub {
	color: $grey;
	cursor: default;
}

.stellarnav.mobile {
	ul {
		margin-right: 0;
	}

	.menu-toggle {
		width: 100%;
		text-align: right;
		height: 20px;
		padding: 0;
		margin: 20px 0;
		padding-right: $default-padding;
	}

	div.logo {
		display: none;
	}
}
